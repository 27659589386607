<template>
    <workspace-wrapper>
        <template #workspace>
            <magic-poker-workspace :state-data="stateData"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import MagicPokerWorkspace from "./MagicPokerWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        cards: [{
                            suit: null,
                            cost: null
                        }],
                        lockedCards: []
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        MagicPokerWorkspace
    }
}
</script>

<style scoped>

</style>
